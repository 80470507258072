import React, { useEffect } from 'react';
import { retriveOrgCouponData } from '../api/PupilApi';

const Hero = () => {

  
  useEffect(() => {
    retriveOrgCouponData()
      .then((response) => {
        const couponData = response.data.data[0]?.couponData || [];
        localStorage.setItem('couponData', JSON.stringify(couponData));
      })
      .catch((error) => {
        console.error("Error fetching coupon data:", error);
      });
    const storedCouponData = localStorage.getItem('couponData');
    if (storedCouponData) {
      console.log("Coupon data found in localStorage:", JSON.parse(storedCouponData));
    } else {
      console.log("No coupon data in localStorage.");
    }
  }, []);
  

  return (
    <div className='max-w-[1640px] mx-auto p-4'>
      <div className='max-h-[500px] relative'>
        {/* Overlay */}
        <div className='absolute w-full h-full text-gray-200 max-h-[500px] bg-black/60 flex flex-col justify-center'>
          <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'>
            The <span className='text-orange-600'>Best</span>
          </h1>
          <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'>
            Briyani <span className='text-orange-600'>Delivered</span>
          </h1>
        </div>
        <img
          className='w-full max-h-[500px] object-cover'
          src='https://images.pexels.com/photos/2814828/pexels-photo-2814828.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
          alt='Delicious Briyani'
        />
      </div>
    </div>
  );
};

export default Hero;
