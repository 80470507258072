import React, { useState } from 'react';
import './App.css';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-react-grids/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';

import { Routes, Route } from 'react-router-dom'; // Import Routes and Route from react-router-dom
import Home from './Components/Home';
import AboutPage from './Components/AboutPage';
import SignInPage from './Components/SignInPage';
import SignUpPage from './Components/SignUpPage';
import ContactPage from './Components/ContactPage';
import FoodOrder from './Components/FoodOrder';
import AdminDashboard from './Components/AdminDashboard';
import ContactAddress from './Components/ContactAddress';
import YourOrder from './Components/YourOrder';
import UserDashboard from './Components/UserDashboard';
import Profile from './Components/Profile';
import ShopDashboard from './Components/ShopDashboard';
import OwnShopDashboard from './Components/OwnShopDashboard';
import PaymentPage from './Components/PaymentPage';
import PaymentSuccessPage from './Components/PaymentSuccessPage';

function App() {
  return (
    <Routes> {/* Wrap Routes here */}
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/yourorder" element={<YourOrder />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/userdashboard" element={<UserDashboard />} />
      <Route path="/admindashboard" element={<AdminDashboard />} />
      <Route path="/shopdashboard" element={<ShopDashboard />} />
      <Route path="/ownshopdashboard" element={<OwnShopDashboard />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/contactaddress" element={<ContactAddress />} />
      <Route path="/paymentpage" element={<PaymentPage />} />
      <Route path="/paymentsuccess" element={<PaymentSuccessPage />} />
     
      <Route path="/foodOrder" element={<FoodOrder />}>
      
        
        {/* Dynamic Route for FoodOrder details */}
        <Route path=":foodId" element={<FoodOrder />} />
      </Route>
    </Routes>
  );
}

export default App;
