import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { data } from '../data/data';
import Navbar from './Navbar';
import Footer from './Footer';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { PaystackButton } from 'react-paystack';
import Swal from 'sweetalert2';
import { retriveOrgCouponData, retriveAdditionalOffer, validateCoupon } from '../api/PupilApi';
import { USERNAMEWITHMAIL } from '../config/Config';
import DatePicker from "react-datepicker";
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const FoodOrder = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [num, setNum] = useState(1);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [showCouponList, setShowCouponList] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState("");  // New state for delivery date
  const [deliveryTime, setDeliveryTime] = useState("");  // New state for delivery time
  const [offerError, setOfferError] = useState(null);
  

  const params = useParams();
  const userIndex = `${params.foodId}`;
  const userData = data[userIndex - 1];

  const increase = () => setNum(num + 1);
  const decrease = () => setNum(num > 1 ? num - 1 : 1);

  const accessToken = localStorage.getItem('accessToken');
  const isLoggedIn = !!accessToken;
  const userDetails = localStorage.getItem(USERNAMEWITHMAIL);

  useEffect(() => {
    if (isLoggedIn) {
      const userDetails = JSON.parse(localStorage.getItem('userDetails'));
      if (userDetails) {
        setEmail(userDetails.email);
        setName(userDetails.name);
        setPhone(userDetails.phone);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const storedCouponData = localStorage.getItem('couponData');
    if (storedCouponData) {
      // If couponData is found in localStorage, set it
      setCoupons(JSON.parse(storedCouponData));
    } else {
      // If no data in localStorage, retrieve from API
      retriveOrgCouponData()
        .then((response) => {
          if (response.data.data && response.data.data.length > 0 && response.data.data[0].couponData) {
            const couponData = response.data.data[0].couponData;
            setCoupons(couponData);
            // Store the fetched coupon data in localStorage
            localStorage.setItem('couponData', JSON.stringify(couponData));
          }
        })
        .catch((error) => {
          console.error("Error fetching coupon data:", error);
        });
    }
  }, []);
  
  useEffect(() => {
    if (appliedCoupon) {
      const originalPrice = userData.price * num;
      const discount = (originalPrice * parseFloat(appliedCoupon.offerPercentage)) / 100;
      setDiscountedPrice(originalPrice - discount);
    } else {
      setDiscountedPrice(userData.price * num);
    }
  }, [num, appliedCoupon, userData.price]);

  const publicKey = process.env.REACT_APP_PAYSTACK_KEY;
  const amount = discountedPrice * 100 * 750;

  const componentProps = {
    email,

    amount,
    metadata: { name, phone },
    publicKey,
    text: "Pay Now",
    onSuccess: () => Swal.fire('Transaction Successful', '🧶🧶🧶🧶🧶🧶🧶', 'success'),
    onClose: () => Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong!',
    }),
  };

  const handleBuyNow = async () => {
    if (isLoggedIn) {
      if (userData.category === 'bucketBriyani' && num < 3) {
        Swal.fire({
          icon: 'warning',
          title: 'Minimum Order Quantity Required',
          text: 'For Bucket Briyani, you must order at least 3 quantities.',
          confirmButtonText: 'OK',
        });
        return; // Exit if the minimum quantity is not met
      }
      if (!deliveryDate || !deliveryTime) {
        Swal.fire({
          icon: 'warning',
          title: 'Delivery Date & Time Required',
          text: 'Please select both a delivery date and time to avail the additional offer.',
          confirmButtonText: 'OK',
        });
        return; // Exit the function if delivery date or time is missing
      }


      const originalPrice = userData.price * num;

      let discountedPriceWithCoupon = originalPrice;

      // Step 1: Apply Coupon Code Offer
      if (appliedCoupon) {
        const couponDiscount = (originalPrice * parseFloat(appliedCoupon.offerPercentage)) / 100;
        discountedPriceWithCoupon = originalPrice - couponDiscount;
      }

      // Step 2: Prepare data for additional offer check
      const additionalOfferDetailsData = {
        email: userDetails,
        originalPrice: discountedPriceWithCoupon,
        discountedPrice: discountedPriceWithCoupon,
        quantity: num,
      };
      const downloadInvoice = (orderDetailsHTML) => {
        const doc = new jsPDF();
      
        // Title for the PDF
        doc.setFontSize(20);
        doc.setFont('helvetica', 'bold');
        doc.text('Aykaruppu Kidai Virundhu - Invoice', 105, 20, { align: 'center' });
      
        // GST details and address with better alignment
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        const addressLines = [
          'GST: 33CTPPK9946M1ZQ',
          'Address: 44, MECHERI, Kukkalpatti,',
          'Salem, Tamil Nadu, 636453',
          'Contact Details: 04298278160, Cell: 6381699905'
        ];
      
        let yPosition = 30; // Starting Y position for address details
        addressLines.forEach((line) => {
          doc.text(line, 105, yPosition, { align: 'center' });
          yPosition += 8; // Line spacing
        });
      
        // Extract text content from HTML string
        const parser = new DOMParser();
        const docHTML = parser.parseFromString(orderDetailsHTML, 'text/html');
        const rows = docHTML.querySelectorAll('tr');
      
        const tableColumn = ['Description', 'Details'];
        const tableRows = [];
      
        // Loop through table rows and extract cell data
        rows.forEach((row) => {
          const cells = row.querySelectorAll('td');
          if (cells.length === 2) {
            const description = cells[0].textContent.trim();
            const details = cells[1].textContent.trim();
            tableRows.push([description, details]);
          }
        });
      
        // Adjust table starting position
        const tableStartY = yPosition + 10;
      
        // Generate the PDF table using autoTable with custom styling
        doc.autoTable({
          startY: tableStartY,
          head: [tableColumn],
          body: tableRows,
          theme: 'grid',
          headStyles: { fillColor: [100, 149, 237], textColor: [255, 255, 255] }, // Custom color for the header
          bodyStyles: { fontSize: 10 }, // Reduce font size for better spacing
          didDrawCell: (data) => {
            const { row, column, cell, table } = data;
      
            // Check if the first column contains "Total Billable Amount"
            const descriptionText = row.raw[0]?.toLowerCase().trim();
      
            if (descriptionText && descriptionText.includes('total billable amount')) {
              if (column.index === 0 || column.index === 1) {
                // Highlight the entire row or the specific cell
                doc.setFillColor(204, 255, 204); // Light green background
                doc.rect(cell.x, cell.y, cell.width, cell.height, 'F'); // Draw filled rectangle
      
                // Set text color and style for the "Total Billable Amount" details
                if (column.index === 1) {
                  doc.setTextColor(0, 128, 0); // Green text color
                  doc.setFont('helvetica', 'bold'); // Bold font
                  doc.text(cell.text, cell.x + cell.padding('left'), cell.y + cell.height / 2 + 3, {
                    align: 'left',
                  });
                }
              }
            }
          },
        });
      
        // Save the PDF as invoice.pdf
        doc.save('invoice.pdf');
      };
      
      
      // Utility function to capitalize the first letter of each field
      const capitalizeFirstLetter = (string) =>
        string.charAt(0).toUpperCase() + string.slice(1).replace(/([A-Z])/g, ' $1');
      // Step 3: Check for Additional Offer
      try {
        const response = await retriveAdditionalOffer(additionalOfferDetailsData);
        let additionalDiscount = 0;
        let additionalOfferMessage = '';

        if (response.data && response.data.data) {
          additionalDiscount = response.data.data; // Assuming this is the additional offer amount
        }

        if (additionalDiscount === 0) {
          additionalOfferMessage =
            'Additional Offer available Only for Yaazhtech Employees or Bulk Order (Buy more than 10 quantity)';
        }

        const finalPrice = discountedPriceWithCoupon - additionalDiscount;
        const totalSavings = originalPrice - finalPrice;
        const handlingCharge = 0;
        const deliveryCharge = 0;
        const gst = ((finalPrice) * 0.18).toFixed(2);
        const totalCost = originalPrice*num;
        const totalBillableAmount = (
          finalPrice +
          handlingCharge +
          deliveryCharge +
          parseFloat(gst)
        ).toFixed(2);

const orderDetails = `
  <div style="font-family: Arial, sans-serif; padding: 0px; border: 1px solid #ccc; border-radius: 5px; background-color: #f9f9f9;">
    <h3 style="color: #333; font-weight: bold;">Order Summary</h3>
    <div style="width: 150%; overflow-x: auto;"> <!-- Ensures the border and table scale together -->
      <table style="width: 100%; border: 1px solid #ccc; border-collapse: collapse;">
        <tr>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">You are ordering:</td>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;"><strong style="color: #007BFF;">${userData.name}</strong></td>
        </tr>
        <tr>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">Quantity:</td>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;"><strong style="color: #555;">${num}</strong></td>
        </tr>
        <tr>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">Original Price:</td>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">Rs. <strong style="text-decoration: line-through; color: #d9534f;">${originalPrice}</strong></td>
        </tr>
        ${appliedCoupon
          ? `<tr>
              <td style="padding: 8px; border-bottom: 1px solid #ccc;">Price After Coupon:</td>
              <td style="padding: 8px; border-bottom: 1px solid #ccc;">Rs. <strong style="color: #5cb85c;">${discountedPriceWithCoupon}</strong></td>
            </tr>`
          : ''
        }
        <tr>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">Additional Discount Applied:</td>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">Rs. <strong style="color: #5bc0de;">${additionalDiscount}</strong></td>
        </tr>
        <tr>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">${additionalOfferMessage}</td>
          <td></td>
        </tr>
        <tr>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">Final Price:</td>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">Rs. <strong style="color: #28a745;">${finalPrice}</strong></td>
        </tr>
        <tr>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">Handling Charge:</td>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">Rs. <strong style="color: #333;">${handlingCharge}</strong></td>
        </tr>
        <tr>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">Delivery Charge:</td>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">Rs. <strong style="color: #333;">${deliveryCharge}</strong></td>
        </tr>
        <tr>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">GST (18%):</td>
          <td style="padding: 8px; border-bottom: 1px solid #ccc;">Rs. <strong style="color: #333;">${gst}</strong></td>
        </tr>
        <tr>
          <td style="padding: 8px; border-top: 1px solid #ccc; font-weight: bold;">Total Billable Amount:</td>
          <td style="padding: 8px; border-top: 1px solid #ccc; font-weight: bold;">Rs. <strong style="color: #20c997;">${totalBillableAmount}</strong></td>
        </tr>
        <tr>
          <td style="padding: 8px; font-weight: bold;">You Saved:</td>
          <td style="padding: 8px; font-weight: bold;">Rs. <strong style="color: #20c997;">${totalSavings}</strong></td>
        </tr>
      </table>
    </div>
  </div>
`;



Swal.fire({
  title: 'Confirm Your Order',
  html: orderDetails,
  icon: 'info',
  showCancelButton: true,
  confirmButtonText: 'Proceed to Pay',
  cancelButtonText: 'Cancel',
  showDenyButton: true,
  denyButtonText: 'Download Invoice',
  preDeny: () => {
    // Trigger the PDF download without closing the modal
    downloadInvoice(orderDetails);
    // Return false to prevent closing the modal
    return false;
  },
}).then((result) => {
  if (result.isConfirmed) {
    navigate('/paymentpage', {
      state: {
        email: userDetails,
        amount: finalPrice,
        name: userData.name,
        phone: userData.phone,
        userData,
        originalPrice,
        num,
        couponCode: appliedCoupon?.couponCode || null,
        preDiscount: originalPrice,
        offerPercentage: appliedCoupon?.offerPercentage || null,
        handlingCharge,
        deliveryCharge,
        gst,
        deliveryDate,
        deliveryTime,
        totalBillableAmount,
      },
            });
          } else if (result.isDenied) {
            downloadInvoice(orderDetails); // Trigger PDF download
          }
        });
      } catch (error) {
        console.error('Error applying additional offer:', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to apply additional offer. Please try again later.',
        });
      }
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Please Login',
        text: 'You need to log in to proceed with your order.',
        confirmButtonText: 'Login',
      }).then(() => {
        window.location.href = '/signin';
      });
    }
  };
 
  const handleGoBack = () => navigate("/");

  const toggleCouponList = () => setShowCouponList(!showCouponList);

  const applyCoupon = (coupon) => {
    if (coupon.couponCode === "FIRSTTIMEUSER") {
      validateCoupon(coupon.couponCode, userDetails)
        .then((response) => {
          // Display the message from the backend API response
          //  alert("Coupon validation message: " + response.data.message);

          if (response.data.data) {
            // If the coupon is valid
            setAppliedCoupon(coupon);
            setCouponCode(coupon.couponCode);
            // Set the valid coupon code
          } else {
            // If the coupon is invalid, display the error message
            setAppliedCoupon(null); // Clear the applied coupon
            setCouponCode(response.data.message); // Display the error message from the backend
            setOfferError(response.data.message); // Set the error message for UI
          }

          setShowCouponList(false); // Hide the coupon list
        })
        .catch((error) => {
          
          const errorMessage = error?.response?.data?.message || "An unknown error occurred.";

          setCouponCode(errorMessage);
          setOfferError(errorMessage); // Set the error message to offer error state
          alert("Error: " + errorMessage);
          console.error("Error fetching coupon data:", error);
        });
    } else {
      // For coupons other than FIRSTTIMEUSER
      setAppliedCoupon(coupon);
      setCouponCode("Coupon Applied Successfully");
      setOfferError("") // Display default message
      setShowCouponList(false); // Hide the coupon list
    }
   

  };
  function getNextSundayDate() {
    const today = new Date();
    const daysUntilSunday = (7 - today.getDay()) % 7;
    today.setDate(today.getDate() + daysUntilSunday);
    return today.toISOString().split('T')[0];
  }
  
  function getMaxSundayDate() {
    const today = new Date();
    today.setFullYear(today.getFullYear() + 1);
    return today.toISOString().split('T')[0];
  } 
  function isValidDate(date) {
    const selectedDate = new Date(date);
    const isSunday = selectedDate.getDay() === 0;
  
    
    const allowedExtraDates = ['2024-12-27', '2024-12-28'];
    const isExtraAllowedDate = allowedExtraDates.includes(date);
  
    return isSunday || isExtraAllowedDate;
  }

  return (
    <>
      <Navbar />
      <div className='max-w-[1320px] mx-auto p-3 md:p-10 my-10'>
        <h1 className='mb-5 text-center font-bold text-blue-700 text-4xl'>ORDER NOW</h1>

        <div className='grid md:grid-cols-2 gap-10'>
          <div>
            <img src={userData.image} className='w-full h-[400px] object-cover shadow-lg' alt={userData.name} />
          </div>
          <div className='text-center px-2'>
            <p className='text-slate-700 text-3xl mb-3'>{userData.name}</p>
            <div className='flex justify-center items-center'>
              <span onClick={decrease} className='cursor-pointer hover:bg-slate-500 p-2'>
                <AiOutlineMinus size={30} />
              </span>
              <span className='mx-4 border-slate-700 border px-5 text-2xl'>{num}</span>
              <span onClick={increase} className='cursor-pointer hover:bg-slate-500 p-2'>
                <AiOutlinePlus size={30} />
              </span>

            </div>
            <div className='mt-4 w-full md:w-[50%] mx-auto bg-slate-400 shadow-lg py-2 text-white text-2xl'>
              Rs.{discountedPrice || userData.price * num}
            </div>
           <div>
      <input
        type="date"
        value={deliveryDate}
        onChange={(e) => {
          const selectedDate = e.target.value;
          if (isValidDate(selectedDate)) {
            setDeliveryDate(selectedDate);
          } else {
            Swal.fire({
              icon: 'info',
              title: 'Please Select Sunday Or Dec 27th and 28th',
              text: 'Please select a Sunday or one of the allowed extra dates (Dec 27, Dec 28, 2024).',
            });
          }
        }}
        className="w-full p-2 my-3 border border-gray-300 rounded-md"
        min={getNextSundayDate()}
        max={getMaxSundayDate()}
        required
      />
      <input
        type="time"
        value={deliveryTime}
        onChange={(e) => setDeliveryTime(e.target.value)}
        className="w-full p-2 my-3 border border-gray-300 rounded-md"
        required
      />
    </div>

            {showCouponList && (
              <div className="bg-gray-100 p-4 rounded-lg shadow-lg mt-4">
                <h2 className="text-lg font-bold mb-3">Available Coupons</h2>
                {coupons.length > 0 ? (
                  <ul>
                    {coupons.map((coupon, index) => (
                      <li key={index} className="mb-2 flex justify-between items-center">
                        <span>{coupon.couponCode} - {coupon.offerPercentage}% Off</span>
                        <button
                          onClick={() => applyCoupon(coupon)}
                          className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                        >
                          Apply
                        </button>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-red-500">No coupons available at the moment.</p>
                )}
              </div>
            )}

            <button
              onClick={toggleCouponList}
              className={`mt-4 w-full md:w-[50%] mx-auto ${showCouponList ? 'bg-red-500' : 'bg-blue-500'} shadow-lg py-2 text-white border-none rounded-none hover:scale-105 text-2xl`}
            >
              {showCouponList ? "Hide Coupons" : "Show Coupons"}
            </button>
            <p className="text-red-600 mt-4">{offerError}</p>
            {appliedCoupon && (appliedCoupon.couponCode) ? (
              appliedCoupon.couponCode ? (
                appliedCoupon.couponCode === "You are not eligible for this Offer" ? (
                  <p className="text-green-600 mt-4">
                    You are not eligible for this Offer
                  </p>
                ) : (
                  <p className="text-green-600 mt-4">
                    Coupon Applied: <strong>{appliedCoupon.couponCode}</strong> - {appliedCoupon.offerPercentage}% Off
                  </p>
                )
              ) : (
                <p className="text-red-600 mt-4">{offerError}</p>
              )
            ) : null}



            <button onClick={handleBuyNow} className='mt-4 w-full md:w-[50%] mx-auto bg-[#D75127] shadow-lg py-2 text-white border-none rounded-none hover:scale-105 text-2xl'>
              Buy it now
            </button>
            <button onClick={handleGoBack} className='mt-4 w-full md:w-[50%] mx-auto bg-gray-400 shadow-lg py-2 text-white border-none rounded-none hover:scale-105 text-2xl'>
              Back
            </button>

            <div className='mt-4 text-orange-600 text-xl'>{userData.description}</div>
          </div>
        </div>
      </div>
      <PaystackButton className="paystack-button hidden" {...componentProps} />
      <Footer />
    </>
  );
};

export default FoodOrder;