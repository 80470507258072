import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { saveOrderDataBff } from '../api/PupilApi';

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    userData,
    amount,       // Final price after applying any offers
    num,          // Quantity
    email,
    phone,
    originalPrice,
    name,
    couponCode,
    offerPercentage,
    orderedUserName,
    preDiscount, 
    deliveryDate,  // Passing delivery date
    deliveryTime,   // Price before discounts (original price)
    totalBillableAmount,
    handlingCharge,
      deliveryCharge,
      gst,

  } = location.state || {};

  const [transactionId, setTransactionId] = useState("");

  if (!userData) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'No order data found. Please try again.',
    }).then(() => navigate('/'));
    return null;
  }

  // Calculate final total cost (total price to be paid by the user)
  const totalCost = amount * num; // Amount already includes discounts/offers


  // Generate the UPI payment URL dynamically with the calculated total cost
  const upiUrl = `upi://pay?pa=6381699905@ptaxis&pn=Payment+for+Order&mc=0000&tid=${transactionId}&url=https://your-website.com&am=${totalBillableAmount}`;

  // Handle transaction submit with validation for transaction ID
  const handleTransactionSubmit = () => {
    if (!/^[a-zA-Z0-9]{5,50}$/.test(transactionId)) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Transaction ID',
        text: 'Please enter a valid transaction ID to proceed.',
      });
      return;
    }

    const orderRequest = {
      totalCost:originalPrice,
      products: [userData],
      shopName: userData.shopName,
      orderStatus: "Pending",
      paymentMethod: "Online",
      shopEmail: userData.shopEmail,
      orderedUserName,
      orderedPersonMobile: phone,
      orderedPersonName: name,
      transactionId,
      quantity: num,
      preDiscount: preDiscount * num, // Original cost before discounts
      couponCode: couponCode || null,
      offerPercentage: offerPercentage || null,
      handlingCharge,
      deliveryCharge,
      gst,
      deliveryDate,  // Passing delivery date
      deliveryTime, 
      totalBillableAmount
    };
    saveOrderDataBff(orderRequest)
      .then(() => {
        Swal.fire({
          title: 'Order Placed',
          text: 'Your order has been placed successfully.',
          icon: 'success',
        }).then(() => {
          navigate("/paymentsuccess", {
            state: {
             
              orderId: transactionId,
              totalCost:userData.totalCost,
              totalBillableAmount:totalBillableAmount,
              shopName: userData.shopName,
              orderStatus: 'Order Placed Successfully',
              
            }
            
          });
        });
      })
     
      .catch((error) => {
        console.error("Error placing order:", error);
        Swal.fire({
          icon: 'error',
          title: 'Order Failed',
          text: 'There was an error placing your order. Please try again.',
        });
      });
  };

  return (
    <div className="payment-container mx-auto max-w-md p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-center text-2xl font-bold mb-4">Scan to Pay</h2>
      <div className="text-center my-4">
        <QRCodeCanvas value={upiUrl} size={200} className="mx-auto" />
        <p className="mt-4 text-lg font-medium">
          Amount: <span className="text-blue-600">Rs. {totalBillableAmount}</span>
        </p>
      </div>
      
      <div className="text-center mt-4 text-md">
        <p className="font-medium text-gray-700">
          Take a screenshot of the QR code and make the payment via UPI apps like GooglePay, PhonePe, or Paytm. 
          After making the payment, please enter the UPI reference number or transaction ID below.
        </p>
      </div>
      
      <div className="mt-6">
        <label htmlFor="transactionId" className="block text-lg font-medium mb-2">
          Enter Transaction ID
        </label>
        <input
          type="text"
          id="transactionId"
          value={transactionId}
          onChange={(e) => setTransactionId(e.target.value)}
          placeholder="Enter transaction ID/UPI Ref No:"
          className="border w-full p-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="mt-8 flex justify-between">
        <button
          onClick={() => navigate("/")}
          className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
        >
          Cancel
        </button>
        <button
          onClick={handleTransactionSubmit}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          Confirm Order
        </button>
        <button
          onClick={() => navigate("/")}
          className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentPage;
