import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import {
  foodFlyOrderStatusUpdate,
  retrieveFoodFlyOrder,
} from "../../src/api/PupilApi"; // Adjust this path as necessary
import { getFoodFlyUserAddressForShopOwners } from "../../src/api/Api"; 

import { USERNAMEWITHMAIL } from "../config/Config";
import "./grid.css";

function OwnShopDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const { name } = location.state || {}; // Get name from location state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [cancelledOrderId, setCancelledOrderId] = useState(null);
  const [cancelReason, setCancelReason] = useState("");
  const [updatingStatus, setUpdatingStatus] = useState(false); // For handling status update loading state

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    const userName = localStorage.getItem(USERNAMEWITHMAIL);
    retrieveFoodFlyOrder(userName)
      .then((res) => {
        toast.success(res.data.message);
        setData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error(
          error.message || "Sorry! Something went wrong. Please try again!"
        );
        setLoading(false);
        console.error(error);
      });
  };

  const handleClickBack = () => {
    navigate("/");
  };

  const openModal = (order) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedOrder(null);
    setCancelledOrderId(null);
    setCancelReason("");
  };

  // Function to update the order status
  const handleStatusUpdate = async (orderId, newStatus, shopEmail) => {
    // Disable updating for cancelled orders without reason
    if (newStatus === "Cancelled" && !cancelReason) {
      alert("Please provide a reason for cancellation.");
      return;
    }

    setUpdatingStatus(true); // Set loading state

    try {
      const payload = {
        orderId,
        orderedUserName: selectedOrder.orderedUserName, // User's email
        shopEmail, // Shop email
        status: newStatus,
        reason: newStatus === "Cancelled" ? cancelReason : "", // Add cancel reason if needed
      };

      // Update status via API
      await foodFlyOrderStatusUpdate(orderId, payload);

      // Fetch updated orders
      const userName = localStorage.getItem(USERNAMEWITHMAIL);
      const res = await retrieveFoodFlyOrder(userName);
      const updatedOrder = res.data.data.find(
        (order) => order.order_id === orderId
      );
      setSelectedOrder(updatedOrder); // Update modal with new order details
      toast.success("Order status updated successfully!");
    } catch (error) {
      console.error("Error updating order status:", error);
      toast.error("Failed to update order status.");
    } finally {
      setUpdatingStatus(false); // End loading state
      setCancelledOrderId(null);
      setCancelReason("");
    }
  };

  const handleCancelClick = (orderId) => {
    setCancelledOrderId(orderId);
  };

  return (
    <div className="control-pane flex-1 m-5">
      <div className="control-section">
        <div className="flex items-center mb-3">
          <h2 className="name pr-3 font-medium">
            Name:<span className="bg-dark font-semibold"> {name}</span>
          </h2>
          <button
            className="select-none rounded-lg float-right bg-black text-white py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md transition-all hover:shadow-lg focus:opacity-[0.85] active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50"
            onClick={handleClickBack}
          >
            <span>Back</span>
          </button>
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Shop Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Delivery Date & Time
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Order Person Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Order Person Mobile
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Order Person Email
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    View Order List
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data?.map((order) => (
                  <tr key={order.order_id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {order.shopName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {order.deliveryDate +"-" +order.deliveryTime}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.orderedPersonName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.orderedPersonMobile}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {order.orderedUserName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        onClick={() => openModal(order)}
                        className="text-blue-500 underline"
                      >
                        View Orders
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Modal for Viewing Order Details */}
      {modalOpen && selectedOrder && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl relative">
            <div className="space-y-4">
              <h2 className="text-xl font-bold mb-4">Order Details</h2>
              <div className="overflow-y-auto max-h-[60vh]">
                <ul className="space-y-4">
                  <li
                    key={selectedOrder.order_id}
                    className="p-4 border border-gray-200 rounded-lg"
                  >
                    <p>
                      <strong>Order ID:</strong> {selectedOrder.order_id}
                    </p>
                    <p>
                      <strong>Total Cost:</strong> Rs.
                      {selectedOrder.totalCost}
                    </p>
                    <p>
                      <strong>Shipping Charge:</strong> Rs.
                      {selectedOrder.shippingCharge}
                    </p>
                    <p>
                      <strong>Order Status:</strong> {selectedOrder.orderStatus}
                    </p>
                    <p>
                      <strong>Shop Name:</strong>{" "}
                      {selectedOrder.shopName || "N/A"}
                    </p>
                    <p>
                      <strong>Shop Email:</strong> {selectedOrder.shopEmail}
                    </p>
                    <p>
                      <strong>Payment Method:</strong>{" "}
                      {selectedOrder.paymentMethod}
                    </p>
                    <p>
                      <strong>Ordered Person Name:</strong>{" "}
                      {selectedOrder.orderedPersonName}
                    </p>
                    <p>
                      <strong>Ordered Person Mobile:</strong>{" "}
                      {selectedOrder.orderedPersonMobile}
                    </p>
                    <p>
                      <strong>Ordered Person UserName:</strong>{" "}
                      {selectedOrder.orderedUserName}
                    </p>

                    {/* Display food item details */}
                    <div className="mt-4">
                      <p className="font-semibold">Food Details:</p>
                      <div className="flex items-center space-x-4 border-b pb-2 mb-2">
                        {/* Display food image */}
                        {selectedOrder.image && (
                          <img
                            src={selectedOrder.image}
                            alt={selectedOrder.name}
                            className="w-16 h-16 object-cover rounded"
                          />
                        )}
                        <div>
                          <p>
                            <strong>Food Name:</strong> {selectedOrder.name}
                          </p>
                          <p>
                            <strong>Category:</strong>{" "}
                            {selectedOrder.category}
                          </p>
                          <p>
                            <strong>Description:</strong>{" "}
                            {selectedOrder.description}
                          </p>
                          <p>
                            <strong>Price:</strong> Rs.{selectedOrder.price}
                          </p>
                          <p>
                            <strong>Quantity:</strong>{" "}
                            {selectedOrder.quantity}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Order status buttons */}
                    <div className="mt-4">
                      <p className="font-semibold">Update Order Status:</p>
                      <div className="space-x-2">
                        <button
                          onClick={() =>
                            handleStatusUpdate(
                              selectedOrder.order_id,
                              "Pending",
                              selectedOrder.shopEmail
                            )
                          }
                          className={`px-4 py-2 text-white rounded-lg ${
                            selectedOrder.orderStatus === "Pending" ||
                            updatingStatus
                              ? "bg-gray-400 cursor-not-allowed"
                              : "bg-blue-500 hover:bg-blue-600"
                          }`}
                          disabled={
                            selectedOrder.orderStatus === "Pending" ||
                            updatingStatus
                          }
                        >
                          Pending
                        </button>
                        <button
                          onClick={() =>
                            handleStatusUpdate(
                              selectedOrder.order_id,
                              "Preparing",
                              selectedOrder.shopEmail
                            )
                          }
                          className={`px-4 py-2 text-white rounded-lg ${
                            selectedOrder.orderStatus === "Preparing" ||
                            updatingStatus
                              ? "bg-gray-400 cursor-not-allowed"
                              : "bg-yellow-500 hover:bg-yellow-600"
                          }`}
                          disabled={
                            selectedOrder.orderStatus === "Preparing" ||
                            updatingStatus
                          }
                        >
                          Preparing
                        </button>
                        <button
                          onClick={() =>
                            handleStatusUpdate(
                              selectedOrder.order_id,
                              "On the way",
                              selectedOrder.shopEmail
                            )
                          }
                          className={`px-4 py-2 text-white rounded-lg ${
                            selectedOrder.orderStatus === "On the way" ||
                            updatingStatus
                              ? "bg-gray-400 cursor-not-allowed"
                              : "bg-green-500 hover:bg-green-600"
                          }`}
                          disabled={
                            selectedOrder.orderStatus === "On the Way" ||
                            updatingStatus
                          }
                        >
                          On the Way
                        </button>
                        <button
                          onClick={() =>
                            handleStatusUpdate(
                              selectedOrder.order_id,
                              "Delivered",
                              selectedOrder.shopEmail
                            )
                          }
                          className={`px-4 py-2 text-white rounded-lg ${
                            selectedOrder.orderStatus === "Delivered" ||
                            updatingStatus
                              ? "bg-gray-400 cursor-not-allowed"
                              : "bg-purple-500 hover:bg-purple-600"
                          }`}
                          disabled={
                            selectedOrder.orderStatus === "Delivered" ||
                            updatingStatus
                          }
                        >
                          Delivered
                        </button>
                        <button
                          onClick={() => handleCancelClick(selectedOrder.order_id)}
                          className={`px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 ${
                            updatingStatus ? "cursor-not-allowed" : ""
                          }`}
                          disabled={updatingStatus}
                        >
                          Cancel
                        </button>
                      </div>

                      {cancelledOrderId === selectedOrder.order_id && (
                        <div className="mt-4">
                          <textarea
                            value={cancelReason}
                            onChange={(e) =>
                              setCancelReason(e.target.value)
                            }
                            placeholder="Enter the reason for cancellation"
                            className="border border-gray-300 rounded-lg p-2 w-full"
                          />
                          <button
                            onClick={() =>
                              handleStatusUpdate(
                                selectedOrder.order_id,
                                "Cancelled",
                                selectedOrder.shopEmail
                              )
                            }
                            className="mt-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                          >
                            Confirm Cancellation
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <button
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2 hover:bg-red-600"
              onClick={closeModal}
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default OwnShopDashboard;
