import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa'; // React Icons for success icon

const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieving data passed from PaymentPage
  const { totalBillableAmount, orderId, shopName, orderStatus } = location.state || {};

  return (
    <div className="payment-success-container mx-auto max-w-md p-6 bg-white shadow-lg rounded-lg relative">
      <div className="text-center">
        {/* Success Icon */}
        <FaCheckCircle className="text-green-500 text-6xl mx-auto mb-4" />

        <h2 className="text-2xl font-bold mb-4 text-green-600">Payment Success</h2>

        <p className="text-xl font-semibold mb-4">
          🎉 Your order has been placed successfully!
        </p>
        <div className="bg-gray-100 p-4 rounded-lg mb-4 shadow-md">
          <p className="text-lg mb-2">
            <span className="font-medium">Order ID:</span> <span className="font-bold">{orderId}</span>
          </p>
          <p className="text-lg mb-2">
            <span className="font-medium">Total Cost:</span> <span className="font-bold">Rs.{totalBillableAmount}</span>
          </p>
          <p className="text-lg mb-2">
            <span className="font-medium">Shop:</span> <span className="font-bold">{shopName}</span>
          </p>
          <p className="text-lg">
            <span className="font-medium">Status:</span> <span className="font-bold">{orderStatus}</span>
          </p>
        </div>

        <div className="flex flex-col space-y-3">
          <button
            onClick={() => navigate('/profile')}
            className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
          >
            Update Your Address
          </button>
          <button
            onClick={() => navigate('/userdashboard')}
            className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600"
          >
            View Order Status
          </button>

          <button
            onClick={() => navigate('/')}
            className="bg-gray-200 text-gray-700 px-6 py-2 rounded-lg hover:bg-gray-300"
          >
            Back to Home
          </button>
        </div>

        {/* Simple floating dots as an animation */}
        <div className="absolute top-0 left-0 w-full h-full -z-10">
          <div className="absolute bg-blue-200 rounded-full w-16 h-16 top-8 left-8 animate-pulse"></div>
          <div className="absolute bg-green-200 rounded-full w-12 h-12 top-16 right-12 animate-ping"></div>
          <div className="absolute bg-yellow-200 rounded-full w-10 h-10 bottom-12 left-10 animate-bounce"></div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
