import React from 'react'
import Navbar from './Navbar'
import Hero from './Hero'
import Footer from './Footer'
import imgOne from '../assets/Rectangle 18.jpg'
import imgTwo from '../assets/Rectangle 19.jpg'

const AboutPage = () => {
  return (
    <>
    <Navbar />
    <Hero />
    <div className='max-w-[1640px] m-auto px-4 py-12'>
        <p className='text-sky-800 font-bold text-3xl text-center mb-5 '>ABOUT US</p>
        <div className='grid grid-cols-1 md:grid-cols-4 items-center g-10'>
            <div className='col-span-3 text-center text-gray-600 bg-purple-300 p-10 border-4 rounded-3xl font-semibold mb-5'>
               Welcome to AyKaruppu Kidai Virundhu Foods, where we believe that great food should be accessible to everyone,
        no matter where they are. Our food delivery service is built on the idea of connecting people
        with their favorite meals conveniently and reliably. At FoodFly Express, we are passionate about two things: delicious food and excellent service.
        We partner with local restaurants, cafes, and chefs to bring you a diverse range of cuisines,
        ensuring there's something for every palate. Our commitment goes beyond just delivering meals. We strive to create a seamless and enjoyable
        experience for our customers. From the moment you place an order to the second it arrives at your
        doorstep, we prioritize efficiency and satisfaction. Sustainability is at the heart of what we do. We aim to reduce our environmental footprint by
        employing eco-friendly packaging and implementing green practices throughout our operations. Thank you for choosing FoodFly Express. We're excited to be a part of your culinary journey,
        delivering joy one meal at a time. Your satisfaction is our priority, and we look forward to
        continuing to serve you with passion and dedication.
            </div>
            <div className='flex flex-col'>
                <img src={imgOne} className='w-full' alt="" />
                <img src={imgTwo} className='w-full' alt="" />
            </div>
        </div>
    </div>

    <Footer />
    
    
    </>
  )
}

export default AboutPage;
