import axios from 'axios';
import {
  ACCESS_TOKEN,
  config,
} from '../config/Config';

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL,
});

export function saveOrderDataBff(orderRequestFoodFly) {
  return instance.post("/foodfly/order/placeOrder", orderRequestFoodFly,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
    
  });
  
}
export function saveGoldLoanDataBff(loanRequest) {
  return instance.put("/gvg/common/updateAppliedFor", loanRequest,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
    
  });
  
}
export function retrieveFoodFlyOrder(email) {
  // alert("userName!!!"+clientUserName)
  return instance.get(`/foodfly/order/orders/${email}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function foodFlyOrderStatusUpdate(orderId, payload) {
  // alert("userName!!!"+clientUserName)
  return instance.put(`/foodfly/order/updateOrderStatus/${orderId}`, payload, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function retrieveGvgPlan(email) {
  // alert("userName!!!"+clientUserName)
  return instance.get(`/gvg/plan/plan/${email}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function savePlanDataBff(orderRequest) {
  return instance.post("gvg/plan/choosePlan", orderRequest,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
    
  });
  
}
export function saveGoldLoanData(loanRequest) {
  return instance.post("gvg/plan/choosePlan", loanRequest,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
    
  });
  
}
export function retrieveUserData() {
  return instance.get("foodfly/order/dashboard/pupil/order", {
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
  });
}
export function retriveOrgCouponData() {
  return instance.get("foodfly/public/idp/getOrgCouponData", {
    headers: {
      "Content-type": "application/json",
      
    },
  });
}

export function retriveAdditionalOffer(orderRequestFoodFly) {
  return instance.post("/foodfly/public/idp/additionalOffer", orderRequestFoodFly,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
    
  });
  
}
export function getGvgOrders() {
 
  return instance.get(
    `/foodfly/secure/admin/dashboard/users/order`,
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    },
  );
}
export function getFoodFlyData(category) {
 
  return instance.get(
    `/foodfly/secure/admin/dashboard/users/${category}`,
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    },
  );
}
export function getFoodFlyDataByCategory(category) {
 
  return instance.get(
    `/foodfly/secure/admin/dashboard/users/byCategory/${category}`,
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    },
  );
}

export function getFoodFlyUserAddressForShopOwners(orderedUserName) {
  return instance.get("foodfly/order/getLastUpdatedAddress", {
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
  });
}
export function retrieveFoodFlyShopOrders(email) {
  // alert("userName!!!"+clientUserName)
  return instance.get(`/foodfly/order/orders/${email}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function validateCoupon(coupon,email) {
  // alert("userName!!!"+clientUserName)
  return instance.get(`/foodfly/order/validateCoupon/${coupon}/${email}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function retrieveFoodFlyByCategory(category) {
  // alert("userName!!!"+clientUserName)
  return instance.get(`/foodfly/secure/admin/dashboard/${category}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function getGvgPlan() {
 
  return instance.get(
    `/gvg/secure/admin/dashboard/users/plan`,
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    },
  );
}
export function getGvgOrderPlan() {
 
  return instance.get(
    `/gvg/secure/admin/dashboard/users/orderAndPlan`,
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    },
  );
}